import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["source", "button"];

  copy(e) {
    if (!!e) e.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.buttonTarget.innerHTML = '<i class="fa fa-check"></i>';
  }
}
