// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";
import _ from "lodash";
import "./application/scroll_position";
require("@lottiefiles/lottie-player");
import "chartkick/chart.js";

window.bootstrap = bootstrap;
